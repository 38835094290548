export const data = {
    personal_info: {
        name: "James Pearson",
        title: "Senior Software Developer",
        location: "United States"
    },
    //    out of 100
    skills: [
        {
            skill_category: 'Programming',
            skill_list: [
                {
                    skill: 'Python',
                    level: '90'
                },
                {
                    skill: 'SQL',
                    level: '90'
                },
                {
                    skill: 'C++',
                    level: '75'
                },
                {
                    skill: 'JavaScript',
                    level: '75'
                },
            ]
        },
        {
            skill_category: 'Cloud Computing',
            skill_list: [
                {
                    skill: 'AWS',
                    level: '85'
                },
                {
                    skill: 'Google Cloud',
                    level: '65'
                }
            ]
        },
        {
            skill_category: 'Non Technical Skills',
            skill_list: [
                {
                    skill: 'Project Management',
                    level: '85',
                },
                {
                    skill: 'Leadership',
                    level: '80',
                }
            ]
        },
        {
            skill_category: 'Other',
            skill_list: [
                {
                    skill: 'Git',
                    level: '90'
                },
                {
                    skill: 'UNIX',
                    level: '80'
                },
            ]
        }
    ],
    experience: [
        {
            company_name: 'Elemica Inc',
            title: 'Senior Python Developer',
            start_date: new Date(2020, 9),
            end_date: new Date(),
            summary: ['I was initially hired to work on an in-house machine learning system that uses natural language processing to process ' +
                'purchase orders. The application was initially built as a simple proof of concept and I was tasked with ' +
                'transforming it into a full fledged stand alone application. As the main developer of the project I was ' +
                'responsible for building of the core functionalities as well as making crucial design decisions on the ' +
                'architecture of the project. The application was built in Python using AWS services (Lambda, Step Functions, RDS, and Textract) ' +
                'and deployed via Bitbucket pipelines using Serverless.',
                'Once delivered, I was asked to lead a greenfield project to migrate incoming emails from an old legacy ' +
                'system to a more modern way of doing things. The team consists of 3-4 developers of which I am the lead developer, ' +
                'my day to day consists of a split of software development, software architecture, and project management. ' +
                'The new project is primarily built in Python using various AWS services (Lambda, API Gateway, SNS, SQS, SES, and DynmoDB) ' +
                'and is deployed via Github actions using Serverless. The project is managed in Jira in an Agile manner, ' +
                'with tickets being raised to describe the work needing to be done, then added to the backlog, which is ' +
                'reviewed every two weeks at the beginning of a sprint. The software architecture is done in Miro as ' +
                ' a collaborative task tp include everyone in the team (both internally and externally) in the decision making process.'
        ]
        },
        {
            company_name: 'Natwest Markets',
            title: 'C# Developer',
            start_date: new Date(2018, 8),
            end_date: new Date(2020, 8),
            summary: ['I worked on an in‑house Rates trading platform, built in C# WPF to replace the ' +
                'legacy C++ trading platform that I had previously worked on.',
                'I led the project to migrate users from the older application to the newer one, by adding or ' +
                'fixing any features deemed essential by the Front Office or Middle Office ' +
                'teams. My day to day responsibilities were split between business analysis, project management, ' +
                'and software development. I often had to talk to all stakeholders and get their feedback ' +
                'on why they were using the legacy system rather than the newer one. Off of the back of the discussions' +
                ' I would raise tickets in Jira to ' +
                'determine the scope of the project, prioritize the tickets, get sign off from upper management, ' +
                'and finally implement the changes myself.']
        },
        {
            company_name: 'Natwest Markets',
            title: 'C++ Developer and 3rd Line Support Team Lead',
            start_date: new Date(2017, 6),
            end_date: new Date(2018, 8),
            summary: ['I managed a team of 3‑5 developers across 4 different applications, with the main goal to fix ' +
                'any critical issues that stopped traders being able to do their day to day job.',
                'There was no 3rd line support team before this one, I needed to establish a new process' +
                ' to collect and prioritise issues in a transparent way. I followed an Agile process where ' +
                'issues were raised to the developers on daily morning calls with the support team. Any critical issues that ' +
                'needed developer involvement and were time sensitive were fixed and patched by my team, less urgent ' +
                'issues were passed onto the team leads of the various applications.',
                'Monthly discussions were had with the leads of support, trading, middle office, and myself to ensure ' +
                'that we were addressing and prioritizing issues according to their needs.',
                'While leading the team I remained a C++ developer fixing those critical issues in the C++ trading platform.'
        ]
        },
        {
            company_name: 'Natwest Markets',
            title: 'Graduate C++ Developer',
            start_date: new Date(2015, 6),
            end_date: new Date(2017, 6),
            summary: ['I worked on a legacy C++ trading platform hosted on a Redhat UNIX server.',
                'My main responsibilities were to implement new features or fix any issues raised by the ' +
                'traders or middle office.',
                'Due to the application being a legacy application, split between C and C++ code that took' +
                ' time to build and release (between 24 to 48 hours), a fairly high number of issues were ' +
                'fixed using Python as it was considered easier to write code in Python that could easily ' +
                'be released. This meant having to analyze the code, try to implement a fix in C or C++, ' +
                'if the changes required were too big, or needed to be done quickly, the fix would be ' +
                'implemented in Python (at times even as a Shell script).']
        },
        {
            company_name: 'Bluestone Group',
            title: 'Junior C# Developer',
            start_date: new Date(2014, 6),
            end_date: new Date(2015, 6),
            summary: ['I worked on an in-house C# .Net application.']
        },
    ],
    education: [
        {
            school_name: 'UCL',
            degree_title: 'Financial Computing',
            start_date: new Date(2013, 8),
            end_date: new Date(2012, 8)
        },
        {
            school_name: 'University of Glasgow',
            degree_title: 'Msc Financial Risk Management',
            start_date: new Date(2011, 8),
            end_date: new Date(2012, 8)
        },
        {
            school_name: 'Kingston University',
            degree_title: 'BSc Financial Economics',
            start_date: new Date(2009, 8),
            end_date: new Date(2012, 5)
        },
    ],
    personal_projects: [
        {
            title: 'MetaMask-Token-Pricer',
            language: 'JavaScript',
            summary: 'A simple web app that connects to a wallet, and fetches the value of the tokens that are stored.',
            link: 'https://github.com/0100101001010000/MetaMask-Token-Pricer'
        },
        {
            title: 'PyTechnicalIndicators',
            language: 'Python',
            summary: 'PyTechnicalIndicators is a Python library with a number of common technical indicators used to analyse financial data.',
            link: 'https://github.com/0100101001010000/PyTechnicalIndicators'
        },
        {
            title: 'PyTechnicalIndicators Examples',
            language: 'Python',
            summary: 'Examples for the PyTechnicalIndicators package',
            link: 'https://github.com/0100101001010000/PyTechnicalIndicators_Examples'
        },
        {
            title: 'BlockchainDB',
            language: 'Python',
            summary: 'A simple NoSQL blockchain database built using Flask',
            link: 'https://github.com/0100101001010000/BlockchainDB'
        },
        {
            title: 'CPP Blockchain db',
            language: 'C++',
            summary: 'C++ version of the Python BlockchainDB',
            link: 'https://github.com/0100101001010000/cpp_blockchain_db'
        },
        {
            title: 'Game of life',
            language: 'Python',
            summary: 'My interpretation of Conway\'s game of life with added perpetual pedator-prey logic',
            link: 'https://github.com/0100101001010000/0100101001010000-s-game-of-life'
        }
    ]
}