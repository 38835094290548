import './App.css';
import {data} from "./data";
import {enableAnalytics} from "./firebase";

function Banner() {
  return (
      <div className='container component'>
          <h1 className='display 1'>{ data.personal_info.name}</h1>
          <h2 className='text-muted'>{ data.personal_info.title}</h2>
          <p><em>{ data.personal_info.location}</em></p>
      </div>
  )
}

const date_format_options = { month: 'long' };

function formatDate(date_to_format) {
    const formatted_month = new Intl.DateTimeFormat('default', date_format_options).format(date_to_format);
    const formatted_year = date_to_format.getFullYear();
    return formatted_month + " " + formatted_year;
}

function ProgressBar({ progress_level }) {
    const style_level = {width: progress_level+'%'};

    return (
        <div className='progress'>
            <div className='progress-bar' style={style_level} role='progressbar' aria-valuenow={progress_level} aria-valuemin='0' aria-valuemax='100'></div>
        </div>
    )
}

function SkillCategory({ skill_list }) {
    const mapped_list = skill_list.map( skill =>
        <div className='container component'>
            <div className='row'>
                <div className='col'>
                    <p>{skill.skill}</p>
                </div>
                <div className='col'>
                    <ProgressBar progress_level={skill.level} />
                </div>

            </div>
        </div>
    );

    return (
        <div>
            {mapped_list}
        </div>
    )
}

function Skills() {
    const skill_categories = data.skills.map ( skill =>
        <div className='col'>
            <h3>{skill.skill_category} </h3>
            <SkillCategory skill_list={skill.skill_list} />
        </div>
    );
    return (
        <div className='container component'>
            <h2 className='border-bottom'>
                <span className="material-symbols-outlined">
                settings
                </span>
               &emsp; Skills</h2>
            <div className='row'>
                {skill_categories}
            </div>
        </div>
    )
}

function Accordion({ item }) {
    // certain values break the accordion
    const regex = /[ #+]/ig
    const stripped_title = item.title.replaceAll(regex, '');
    const item_id = stripped_title + 'Id'
    const item_target = '#collapse' + stripped_title
    const item_controls = 'collapse' + stripped_title

    const summary = item.summary.map(paragraph =>
        <p>{paragraph}</p>
    )

    return (
        <div className='accordion-item'>
            <h3 className='accordion-header' id={item_id}>
                <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target={item_target} aria-expanded='false' aria-controls={item_controls}>
                    { item.title } @ <i>{item.company_name}</i>
                </button>
            </h3>
            <div id={item_controls} className='accordion-collapse collapse' aria-labelledby={item_id} data-bs-parent='#workExperienceAccordion'>
                <div className='accordion-body'>
                    <i>{formatDate(item.start_date)} - {formatDate(item.end_date)}</i>
                    <p className='summary'>{summary}</p>
                </div>
            </div>
        </div>
    )

}

function WorkExperience() {
    const experience_list = data.experience.map (work =>
        <Accordion item={work} />
    );
  return (
      <div className='container component'>
          <h2 className='border-bottom'>
              <span className="material-symbols-outlined">
                work
                </span>
               &emsp; Work Experience</h2>
          <div className='accordion' id='workExperienceAccordion'>
              {experience_list}
          </div>
      </div>
  )
}

function Education() {
    const education_list = data.education.map(school =>
        <div className='col education'>
            <h3>{school.degree_title}</h3>
            <h5 className='text-muted'>{school.school_name}</h5>
            <i>{formatDate(school.start_date)} - {formatDate(school.end_date)}</i>
        </div>
    );
  return (
      <div className="container component">
          <h2 className='border-bottom'>
              <span className="material-symbols-outlined">
                school
                </span>
               &emsp; Education</h2>
          <div className='row row-cols-3 row-cols-sm-1'>
              {education_list}
          </div>
      </div>
  )
}

function PersonalProjects() {
    const project_list = data.personal_projects.map( project =>
        <div className='col'>
            <div className='card'>
                <div className='card-body'>
                    <h3 className='card-title'>{project.title}</h3>
                    <h5 className='text-muted'>{project.language}</h5>
                    <p className='card-text'>{project.summary}</p>
                    <a className='btn btn-primary' href={project.link} >See code</a>
                </div>
            </div>

        </div>
    );
  return (
      <div className='container component'>
          <h2 className='border-bottom'>
              <span className="material-symbols-outlined">
                terminal
                </span>
               &emsp; Personal Projects</h2>
          <div className='row row-cols-3 row-cols-sm-1'>
              {project_list}
          </div>
      </div>
  )
}

function App() {
    enableAnalytics();
  return (
    <div className="App">
        {/*scrollspy navbar?*/}
        <Banner />
        <br />
        <Skills/>
        <br />
        <WorkExperience />
        <br />
        <Education />
        <br />
        <PersonalProjects />
        <br />
    </div>
  );
}



export default App;