// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, setAnalyticsCollectionEnabled } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDfyZVXzTv3wnocevr3cHLr17QlFDKMocg",
    authDomain: "cv-websites-f808a.firebaseapp.com",
    projectId: "cv-websites-f808a",
    storageBucket: "cv-websites-f808a.appspot.com",
    messagingSenderId: "877599271202",
    appId: "1:877599271202:web:131bb05c7b1eb55cfedd7b",
    measurementId: "G-QQR4VY48CQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export function enableAnalytics() {
    setAnalyticsCollectionEnabled(analytics, true)
}

